<template>
  <div class="main-view-package">
    <a-button class="btn-back" @click="back()">
      <div class="d-flex">
        <img src="@/assets/images/arrowBack.svg" class="mr-1" alt="" />
        {{ lbl['btn_back'] }}
      </div>
    </a-button>

    <div class="box-view-package">
      <div class="box-content title text-capitalize">
        <img
          src="@/assets/images/selfonboard/package.svg"
          class="mr-1 icon-package"
          alt=""
        />
        {{ lbl['selfonboard-your-package'] }} : {{ mapName(packageType) }}
      </div>
      <hr class="mt-0" />
      <div class="box-content">
        <div
          v-for="topic in compareData.PackageFeatures"
          :key="`topic-${topic.PackageId}`"
        >
          <div
            v-if="topic.PackageId == packageType.toLowerCase()"
            class="compare-container"
          >
            <div class="features-topic">
              {{ lbl['package-select-package-feature-title'] }}
            </div>
            <section class="package-topic">
              <div class="package-topic-text">
                <div class="package-name-container">
                  <span class="package-name">
                    {{ topic.PackageName }}
                  </span>
                  <div v-if="topic.IsRecommended" class="package-recommended">
                    <span>
                      {{ lbl['package-select-package-recommended-text'] }}
                    </span>
                    <img src="@/assets/images/shield.svg" alt="" />
                  </div>
                </div>
                <div
                  v-if="crmPackage.PackagePlanType"
                  class="package-price-container"
                >
                  <span
                    v-if="crmPackage.PackagePlanType.toLowerCase() == 'yearly'"
                    class="package-price"
                  >
                    {{ formatNumber(topic.YearlyPrice) }}
                  </span>
                  <span v-else class="package-price">
                    {{ formatNumber(topic.MonthlyPrice) }}
                  </span>
                  <span
                    v-if="crmPackage.PackagePlanType.toLowerCase() == 'yearly'"
                    class="package-price-unit"
                  >
                    {{
                      lbl['package-select-package-monetary-unit-per-year-text']
                    }}
                  </span>
                  <span v-else class="package-price-unit">
                    {{
                      lbl['package-select-package-monetary-unit-per-month-text']
                    }}
                  </span>
                </div>
              </div>
            </section>
            <section
              class="features-sub-topic"
              v-for="topic in compareData.PackageFeatures"
              :key="`topic-${topic.PackageId}`"
            >
              <div v-if="topic.PackageId == packageType.toLowerCase()">
                <section
                  class="features-sub-topic"
                  v-for="(
                    featureGroupItem, index
                  ) in compareData.MasterFeatures"
                  :key="index"
                >
                  <div
                    :id="featureGroupItem.FeatureGroupId"
                    class="feature-group-name"
                  >
                    {{ featureGroupItem.FeatureGroupName }}
                  </div>
                  <div
                    class="feature-detail"
                    v-for="(featureItem, index) in featureGroupItem.Features"
                    :key="index"
                  >
                    <div :id="featureItem.FeatureId" class="feature">
                      {{ featureItem.FeatureName }}
                    </div>
                    <div class="feature-compare">
                      <img
                        v-if="
                          mapFeature(featureItem.FeatureId, topic.Features) ==
                          'checked'
                        "
                        :src="
                          require(`@/assets/images/${mapFeature(
                            featureItem.FeatureId,
                            topic.Features,
                          )}.svg`)
                        "
                        alt=""
                      />
                      <div v-else>
                        {{ mapFeature(featureItem.FeatureId, topic.Features) }}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import BzbsPackage from '@/core/Package/service/BzbsPackage'
import _ from 'lodash'

export default {
  components: {},
  mixins: [Mixin],
  data() {
    return {
      packageType: this.$route.params.package,
      packageData: {},
      compareData: [],
      crmPackage: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.handleLoading(true)
      Promise.all([this.getComparePackage()])
      this.crmPackage = Account.getCachePackage()
    },
    getPackage() {
      return new Promise(resolve => {
        BzbsPackage.apiGetPackage()
          .then(res => {
            this.packageData = res.data
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            console.log(error)
            this.handleLoading(false)
            this.isError = true
            this.errorMessege = error.response.data.error.message
            resolve(error)
          })
      })
    },
    getComparePackage() {
      return new Promise(resolve => {
        BzbsPackage.apiGetComparePackage()
          .then(res => {
            this.compareData = res.data.data
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            console.log(error)
            this.handleLoading(false)
            this.isError = true
            this.errorMessege = error.response.data.error.message
            resolve(error)
          })
      })
    },
    back() {
      this.$router.push({ name: 'configprofile' }).catch(err => {
        console.log('router err', err)
      })
    },
    formatNumber(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    mapFeature(featureId, featureArr) {
      let mappingFeature = featureArr.find(
        feature => feature.FeatureId === featureId,
      )
      if (mappingFeature == undefined) {
        return '-'
      } else {
        if (mappingFeature.FeatureDetail == null) {
          return 'checked'
        } else {
          return mappingFeature.FeatureDetail
        }
      }
    },
    mapName(id) {
      var findPackage = _.find(
        this.compareData.PackageFeatures,
        function (item) {
          return item.PackageId.toLowerCase() == id.toLowerCase()
        },
      )
      return findPackage ? findPackage.PackageName : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/main.scss';

.main-view-package {
  min-height: 100vh;
  background-image: url(/vue/img/Revamp_bg.1e80bddd.png) !important;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: contain;
  background-repeat: round;
  padding-bottom: 100px;

  .icon-package {
    width: 38px;
  }

  .btn-back {
    font-size: 16px;
    font-weight: bold;
    color: $color-grey-75 !important;
    background-color: $color-white !important;
    margin: 60px 0px 0px 100px;
  }

  .box-view-package {
    border-radius: 6px;
    background-color: $color-white;
    margin: 30px 100px 0px 100px;

    .title {
      font-size: 34px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.25px;
      text-align: left;
      color: $text-dark;
    }
  }

  .box-content {
    padding: 20px 30px;

    .compare-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: solid 1px $border;
      font-size: 14px;
      color: $color-grey-61;

      .features-topic {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        align-self: end;
        color: $text-light-gray;
        padding: 24px 32px;
        border-right: solid 1px $border;
      }
      .package-topic {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 24px 32px;
        text-align: left;
        border-right: solid 1px $border;

        .package-topic-text {
          .package-name-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 12px;
            justify-content: space-between;
            align-items: center;
            .package-name {
              font-size: 20px;
              font-weight: 500;
              color: $color-grey-61;
            }
            .package-recommended {
              width: fit-content;
              border-radius: 2px;
              background-color: $color-grey-fa;
              color: $text-light-gray;
              font-size: 14px;
              font-weight: 500;
              padding: 4px;
            }
          }
          .package-price-container {
            font-weight: 500;

            .package-price {
              font-size: 34px;
              text-align: left;
              color: $text-dark;
            }
            .package-price-unit {
              font-size: 14px;
              color: $text-light-gray;
            }
          }
        }
        .select-package {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          background-color: $orange;
          border-radius: 6px;
          padding: 8px 16px;
          margin-top: 16px;
          border: 0;
        }
      }
      .features-sub-topic {
        border-right: solid 1px $border;
        text-align: left;
        grid-column-start: 1;
        grid-column-end: 4;

        .feature-group-name {
          grid-column-start: 1;
          grid-column-end: 3;
          padding: 8px 32px;
          font-weight: 500;
          background-color: $color-grey-fa;
        }
        .feature-detail {
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-bottom: solid 1px $border;
          background-color: #ffffff;

          .feature {
            padding: 8px 32px;
          }
          .feature-compare {
            border-left: 1px solid $border;
            padding: 8px 32px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            align-self: center;
          }
        }
      }
      .selected-package-section {
        border: solid 1px $border;
        .wrapper {
          margin: 16px 32px;
          .select-package-button {
            width: 100%;
            color: #fff;
            padding: 16px 24px;
            border-radius: 6px;
            border: 0;
            background-color: $orange;
            margin-bottom: 8px;

            .title {
              font-size: 16px;
              font-weight: bold;
            }
            .price {
              font-size: 12px;
              margin-top: 4px;
            }
          }
          .price-detail {
            font-size: 14px;
            font-weight: 500;
            padding: 0;
            margin: 0;
            color: $text-light-gray;
          }
          .annual-payment-text {
            font-weight: 500;
            color: $text-dark-gray;
          }
          .select-package-per-year-button {
            width: 100%;
            margin-top: 16px;
            padding: 8px;
            font-size: 16px;
            font-weight: bold;
            color: $orange;
            background-color: #ffffff;
            border: 1px solid $orange;
            border-radius: 6px;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 600px) and (orientation: portrait) {
    .box-view-package {
      margin: 10px 10px 0px 10px;
      .title {
        font-size: 20px;
      }
      .box-content {
        padding: 10px 15px;
        .features-topic {
          padding: 5px;
        }
        .compare-container {
          .package-topic {
            padding: 5px;
          }
        }
        .features-sub-topic {
          .feature-group-name {
            padding: 5px;
          }
          .feature-detail {
            .feature {
              padding: 5px;
            }
          }
        }
      }
    }
    .btn-back {
      margin: 10px 10px 0px 10px;
    }
  }
}
</style>
